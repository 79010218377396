import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';

export const UserProfile = (props) => {
    const {walletInfo} = props;
    return (
        <Wrapper>
            <Avatar/>
            <Address>
                {walletInfo?.address}
            </Address>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Avatar = styled.div`
  width: 60px;
  height: 60px;
  border: 1px solid #fff;
  border-radius: 100px;
`;

const Address = styled.div`
  font-size:  12px;
  max-width: 180px;
  margin-top: 10px;
`;
