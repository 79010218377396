import styled from "styled-components";
import * as React from "react";
import {Content, TitleBlock} from "../../common-elements";
import {NftCard} from "../NftCard";
import nftDemo from './../../assets/images/demo_nft.jpg';

export const OurNftBlock = () => {
    return (
        <Wrapper>
            <Content>
                <TitleBlock>
                    Our NFT
                </TitleBlock>

                <NftContainer>
                    <div className={'item'}>
                        <NftCard imgSrc={nftDemo}/>
                    </div>
                    <div className={'item'}>
                        <NftCard imgSrc={nftDemo}/>
                    </div>
                    <div className={'item'}>
                        <NftCard imgSrc={nftDemo}/>
                    </div>
                </NftContainer>
            </Content>
        </Wrapper>
    )
}


const Wrapper = styled.div`
 
`;

const NftContainer = styled.div`
    display: flex;
    margin-top: 43px;
    justify-content: space-between;
    .item{
      margin-right: 10px;
    }
`;
