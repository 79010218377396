import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {Content} from "../../common-elements";
import {SOUND_MARKS} from "../SoundLoader";

export const Footer = () => {
    return (
        <Wrapper>
            <Content>
                <InfoContainer>
                <div className={'column'}>
                    <div className={'docs-container'}>
                        <div className={`docs-item ${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`}>Пользовательское соглашение</div>
                        <div className={`docs-item ${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`}>Правила использования</div>
                    </div>

                    <div className={'copper'}>
                        © 2022 Права защищены
                    </div>
                </div>


                <SocialContainer>
                    <SocialLink className={`${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`}/>
                    <SocialLink className={`${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`}/>
                    <SocialLink className={`${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`}/>
                    <SocialLink className={`${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`}/>

                </SocialContainer>
                </InfoContainer>
            </Content>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  background-color: #080B14;
  width: 100%;
  min-height: 133px;
  position: relative;
  bottom: 0;
`;

const InfoContainer = styled.div`
  box-sizing: border-box;
  padding: 32px 10px;
  display: flex;
  justify-content: space-between;
  font-family: ${({theme}) => theme.fonts.ubuntuMono};
  font-size: 14px;

  .column{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  
  .docs-container{
    display: flex;
  }
  
  .docs-item{
    color: #9FA8AD;
    transition: .2s;
    margin-right: 20px;
    
    :hover{
      opacity: 0.4;
    }
  }
  .copper{
    color: #9FA8AD;
  }

`;

const SocialContainer = styled.div`
    display: flex;
    
`;


const SocialLink = styled.div`
  transition: .2s;
  height:50px;
  width:  50px;

  background: rgba(255, 255, 255, 0.05);
  opacity: 0.8;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  :hover {
    opacity: 0.2;
  }
  
  margin-right: 10px;
  
  :last-of-type{
    margin-right: 0;
  }
`;

