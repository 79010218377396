import styled from "styled-components";
import * as React from "react";
import {Content, TitleBlock} from "../../common-elements";
export const WhatSpecialNft = () => {
    return (
        <Wrapper>
            <Content>
               <div className={'margin-top'}>
                   <div className={'label'}>
                       <TitleBlock>
                           What special
                       </TitleBlock>
                   </div>
                   <StatsBlock>
                       <div className={'item'}>
                           <div className={'value'}>
                               -
                           </div>
                           <div className={'description'}>
                                Uniq
                           </div>
                       </div>
                       <div className={'item'}>
                           <div className={'value'}>
                               620
                           </div>
                           <div className={'description'}>
                            Uniq galaxy
                           </div>
                       </div>
                       <div className={'item'}>
                           <div className={'value'}>
                               -
                           </div>
                           <div className={'description'}>
                               интересная
                               цифра
                           </div>
                       </div>
                       <div className={'item'}>
                           <div className={'value'}>
                               -
                           </div>
                           <div className={'description'}>
                               интересная
                               цифра
                           </div>
                       </div>
                   </StatsBlock>

                   <div className={'block-description'}>
                   The Origin Uprising is an economic strategy in the space setting, representing the investment process wrapped in a gamified format.
                    Just as crypto-investors stake money into decentralized pools of liquidity, players can invest in-game NFTs and tokens into their
                     Star Systems which will generate stable income, growing along with the development of their systems.
                     Thus, the universe of The Orion Uprising will have a living, self-regulating economy, where hundreds upon hundreds of nations, controlled by players, will develop, cooperate and compete with each other in the race for prosperity and power.
                   </div>
               </div>

            </Content>
        </Wrapper>
    )
}


const Wrapper = styled.div`
    

  
    .block-description{
      font-family:  ${({theme}) => theme.fonts.ubuntuMono};
      font-weight: 400;
      font-size: 28px;
      line-height: 48px;
      color: ${({theme}) => theme.colors.white};
      margin-top: 60px;
      opacity: 0.9;
    }

`;


const StatsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 50px;
  justify-content: space-between;
    .label {
      
    }
  
  .item {
    font-family: ${({theme}) => theme.fonts.seldom};
    color: ${({theme}) => theme.colors.white};
    max-width: 260px;
    margin-right: 15px;
  }

  .value {
    border-bottom: 2px solid #2D2D2D;
    font-size: 24px;
  }

  .description {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.8;
  }
`;
