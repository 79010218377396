export const appTheme = {
    colors: {
        white: "#FFFFFF",
        darkGrey: "#2D2D2D",
        black: "#000",
        blue: "#267BFF",
        lightBlue: "rgba(38, 123, 255, 0.2)",
        lightGrey: "rgba(250, 250, 255, 0.5)",
    },
    fonts: {
        seldom: 'Seldom Scene Regular',
        ubuntuMono: "'Ubuntu Mono', monospace"
    }
};
