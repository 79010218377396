import React, {useEffect, useMemo, useState} from 'react';

export const ApplicationCoreContext = React.createContext(null);

export const  ApplicationCoreContextProvider = (
    {children}
) => {
    const [appIsLoaded, setAppIsLoaded] = useState(false);



    useEffect(()=>{
        window.addEventListener('load', (event) => {
            setAppIsLoaded(true);
        });

        return () => {
            window.removeEventListener('load', () => {});
        }
    }, [])


    const initialContextState = useMemo(() => ({
        appIsLoaded
    }), [appIsLoaded])

    return(
        <ApplicationCoreContext.Provider value={initialContextState}>
            {children}
        </ApplicationCoreContext.Provider>
    )
}
