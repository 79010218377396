import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import {RoadmapItem} from "./RoadMapItem";
import {Content, TitleBlock} from "../../common-elements";


const config = [
    {
        key: '1',
        active: true,
        steps: [
            {
                value: 'Genesis',
                status: true
            },
            {
                value: `Q1/2024`,
                status: false
            }
        ]
    },
    {
        key: '2',
        active: true,
        steps: [
            {
                value: 'Adaptation',
                status: true
            },
            {
                value: 'Q2/2024',
                status: false
            }
        ]
    },
    {
        key: '3',
        active: true,
        steps: [
            {
                value: 'Expansion',
                status: true
            },
            {
                value: 'Q3/2024',
                status: false
            }
        ]
    },
    {
        key: '4',
        active: true,
        steps: [
            {
                value: 'Extermination',
                status: true
            },
            {
                value: 'Q4/2024',
                status: false
            }
        ]
    },
    {
        key: '5',
        active: true,
        steps: [
            {
                value: 'Exploitation',
                status: true
            },
            {
                value: 'Q1/2025',
                status: false
            },
            {
                value: 'Q1/2025',
                status: false
            },
            {
                value: 'Q1/2025',
                status: false
            }
        ]
    }
]


export const RoadmapBlock = () => {
    return (
        <Wrapper>
            <Content>
                <div className={'title-bl'}>
                    <TitleBlock>THE ROAD AHEAD</TitleBlock>
                </div>
            </Content>
            <RoadmapItem config={config}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  .title-bl{
    text-align: center;
    margin-bottom: 80px;
    position: relative;
    z-index: 1;
  }
  
  margin-bottom: 150px;
`;

