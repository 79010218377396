import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import demoPartner from './../../assets/images/demo_partner.png';
import {Content} from "../../common-elements";
import {SOUND_MARKS} from "../SoundLoader";


export const PartnersBlock = () => {
    return (
        <Wrapper>
            <Content>
            <PartnerContainer>
                <PartnerItem className={`item ${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`} href={'#'} src={demoPartner}/>
                <PartnerItem className={`item ${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`} href={'#'} src={demoPartner}/>
                <PartnerItem className={`item ${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`} href={'#'} src={demoPartner}/>
                <PartnerItem className={`item ${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`} href={'#'} src={demoPartner}/>

            </PartnerContainer>
            </Content>
        </Wrapper>
    );
}

const Wrapper = styled.div`
`;

//220*135

const PartnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const PartnerItem = styled.a`
  display: block;
  width: 220px;
  height: 135px;
  background-image: url(${(props) => props.src});
  box-sizing: border-box;
  padding: 5px;
  transition: .2s;
  opacity: 0.2;
  
  :hover {
    opacity: 1;
  }
`;
