import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import vv from './../assets/loader-n.mp4';
export const MainLoader = () => {
    const [show, setShow] = useState(true);


    useEffect(() => {
        document.getElementById('video-player').controls = false;

        setTimeout(() => {
            setShow(true)
            document.getElementById('video-player').play();
        }, 100)

       setTimeout(() => {
           setShow(false);
       }, 7000)
    }, [])
    return show ? (
        <Wrapper>
            <video id='video-player'autoplay="autoplay" loop preload="metadata" width="320" height="240" controls>
                <source src={vv} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </Wrapper>
    ) : null;
}

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: #000;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

