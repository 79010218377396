// import Web3 from 'web3'


export default class Crypto {

    static async initiateCryptoToken() {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
        } else {
            console.log('::>Error: ',  "No ETH browser extension detected.");
            return null;
        }
        return  window.localStorage.getItem("dApp/userToken");
    }


    static async loginUserWithEth() {
        if (window.web3) {
            try {
                // We use this since ethereum.enable() is deprecated. This method is not
                // available in Web3JS - so we call it directly from metamasks' library
                const selectedAccount = await window.ethereum
                    .request({
                        method: "eth_requestAccounts",
                    })
                    .then((accounts) => accounts[0])
                    .catch(() => {
                        throw Error("No account selected!");
                    });
                window.localStorage.setItem("dApp/userToken", selectedAccount);

                return selectedAccount;
            } catch (error) {
                throw Error(error);
                console.log('::>Error: ', error);
            }
        } else {
            console.log("::>Error: ", "No ETH brower extension detected.");
            throw Error("No ETH brower extension detected.");
            return null;
        }
    }
}
