import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import mouseHoverSound from './../assets/audio/mouse_hover.mp3';
import mouseClickSound from './../assets/audio/mouse_click.mp3';


export const SOUND_MARKS = {
    HOVER: 'cl-sound-mouse-hover',
    CLICK: 'cl-sound-mouse-click'
}


export const SoundLoader = () => {
    const mouseHoverSoundRef = useRef(null);
    const mouseClickSoundRef = useRef(null);

    const handleMouseHover = (event) => {
        mouseHoverSoundRef.current.load();
        mouseHoverSoundRef.current.muted = false;
        setTimeout(() => {
            mouseHoverSoundRef.current.volume = 0.05;
            mouseHoverSoundRef.current.play();
        }, 1)
    }

    const handleMouseClick = (event) => {
        mouseClickSoundRef.current.load();
        mouseClickSoundRef.current.muted = false;
        setTimeout(() => {
            mouseClickSoundRef.current.volume = 0.08;
            mouseClickSoundRef.current.play();
        }, 1)
    }



    const initAudio =() => {
        mouseClickSoundRef.current.muted = true;
        mouseHoverSoundRef.current.muted = true;
    }



    useEffect(() => {
        initAudio();

        setTimeout(() => {
            const getClassKeys = Object.keys(SOUND_MARKS);

            if(getClassKeys != null && getClassKeys.length){
                getClassKeys.forEach((key) => {
                    const soundItems = document.getElementsByClassName(SOUND_MARKS[key]);
                    if(soundItems != null && soundItems.length){
                        for(let i = 0; i < soundItems.length; i++) {
                            if(SOUND_MARKS[key] === SOUND_MARKS.CLICK){
                                soundItems[i].addEventListener("click", (event) => handleMouseClick(event));
                            }else if(SOUND_MARKS[key] === SOUND_MARKS.HOVER) {
                                soundItems[i].addEventListener("mouseover", (event) => handleMouseHover(event));
                            }
                        }
                    }
                });
            }


        }, 3000)



        return () => {
            // document.removeEventListener("keydown");
        }




    }, [])

    return (
        <Wrapper>

            <audio src={mouseHoverSound} ref={mouseHoverSoundRef}/>
            <audio src={mouseClickSound} ref={mouseClickSoundRef}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  position: absolute;
  width:1px;
  height:1px;
  overflow: hidden;
  top: 0;
  left: 0;
`;
