import styled from "styled-components";
import * as React from "react";
import {SOUND_MARKS} from "./SoundLoader";
import Crypto from './../services/crypto';
import {useContext, useEffect, useState} from "react";
import {isBrowser} from "../services/utils";
import {UserProfileContext} from "../context/UserProfileContext";

export const TopMenuSection = () => {
    const contextUserProfile = useContext(UserProfileContext);

    return (
        <Wrapper>
            <Menu>
                <div className={`item ${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`}>
                    The game
                </div>
                <div className={`item ${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`}>
                    About us
                </div>
                <div className={`item ${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`}>
                    Marketplace
                </div>
                <div className={`item ${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`}>
                    Whitepaper
                </div>
            </Menu>

            {contextUserProfile?.userTokenWallet == null ? (

                <RegistrationBt
                    onClick={async () => {
                        try {
                            const token = await Crypto.loginUserWithEth();
                        } catch (e) {
                            console.log('error Log:> [TopMenuSection.js] :=', e);
                        }
                    }}
                    className={`item ${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK}`}>
                    PLAY GAME
                </RegistrationBt>
            ) : null}
        </Wrapper>
    )
}


const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;


const RegistrationBt = styled.div`
  background: #267BFF;
  border: 2px solid #267BFF;
  box-sizing: border-box;
  border-radius: 4px;
  width: fit-content;
  padding: 20px 25px;
  transition: .2s;

  :hover {
    box-shadow: 0 0 8px 1px #277bff;
  }
`;


const Menu = styled.div`
  display: flex;
  align-items: center;

  .item {
    margin-right: 30px;
    font-family: ${({theme}) => theme.fonts.ubuntuMono};
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    display: block;
    position: relative;
    color: rgba(250, 250, 255, 0.5);;
    transition: 0.2s;

    ::after {
      display: none;
      content: "";
      width: 30%;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      background-color: #FFFFFF;
    }

    :hover {
      color: #fff;

      ::after {
        display: block;
      }
    }
  }
`;
