import * as React from "react"
import styled, {ThemeProvider} from 'styled-components';
import {appTheme} from "../theme";
import './../global.css';
import {HeaderBlock} from "../components/landing/header-block";
import {WhatSpecialNft} from "../components/landing/what-special-block";
import {OurNftBlock} from "../components/landing/our-nft-block";
import bgStats from './../assets/images/bg_stars.png'
import footerAnim from './../assets/images/footer_animation.gif'
import {Footer} from "../components/landing/Footer";
import {PartnersBlock} from "../components/landing/Partners";
import {SoundLoader} from "../components/SoundLoader";
import {RoadmapBlock} from "../components/roadmap/Roadmap";
import footContainerBg from './../assets/images/partner_block_bg.png';
import mouseHoverSound from "../assets/audio/mouse_hover.mp3";
import {useEffect, useRef} from "react";
import bgSoundWind from './../assets/audio/bg_sound.mp3';
import {MainLoader} from "../components/MainLoader";
import SEO from "../components/seo";
import {UserProfileContextProvider} from "../context/UserProfileContext";
import {ApplicationCoreContextProvider} from "../context/ApplicationCoreContext";

// markup
const IndexPage = () => {
    const bgSoundWindRef = useRef(null);


    useEffect(() => {
        if (bgSoundWindRef != null && bgSoundWindRef?.current != null) {
            bgSoundWindRef.current.volume = 0.15;
            setTimeout(() => {
                const prm = bgSoundWindRef.current.play();
                if (prm !== undefined) {
                    prm.then(() => {
                        bgSoundWindRef.current.muted = false;
                        bgSoundWindRef.current.play();

                    }).catch(error => {
                        bgSoundWindRef.current.muted = true;
                        bgSoundWindRef.current.play();
                    });
                }
            }, 1000)
        }
    }, [])


    return (
        <ApplicationCoreContextProvider>
            <ThemeProvider theme={appTheme}>
                <UserProfileContextProvider>
                    <div id={'init-page-run'}>
                        <audio muted="muted" autoplay src={bgSoundWind} ref={bgSoundWindRef}/>
                    </div>
                    <SEO/>
                    <MainLoader/>
                    <Wrapper>
                        <SoundLoader/>
                        <HeaderBlock/>
                        <SpaceContainer>
                            <div className={'maring-top-special'}>
                                <WhatSpecialNft/>
                            </div>
                            <div className={'nft-marting'}>
                                <OurNftBlock/>
                            </div>
                            <FooterAnim src={footerAnim}/>
                        </SpaceContainer>
                        <FootContainer>
                            <RoadmapBlock/>
                            <PartnersBlock/>
                        </FootContainer>

                        <Footer/>
                    </Wrapper>

                </UserProfileContextProvider>
            </ThemeProvider>
        </ApplicationCoreContextProvider>
    )
}

const Wrapper = styled.div`
`;

const SpaceContainer = styled.div`
  position: relative;
  width: 100%;

  .nft-marting {
    position: relative;
    z-index: 2;
    margin-top: 200px;
  }

  .space-bg {
    width: 100%;
    position: absolute;
    background-image: url(${bgStats});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    box-sizing: border-box;
  }


  .maring-top-special {
    padding-top: 370px;
  }
`;


const FooterAnim = styled.img`
  position: absolute;
  bottom: -360px;
  left: 0;
  z-index: 1;
`;


const FootContainer = styled.div`
  background-image: url(${footContainerBg});
  background-position: center;
  min-height: 900px;
  width: 100%;
  background-repeat: repeat-x;
  margin-top: 370px;
  margin-bottom: 150px;
`;


export default IndexPage
