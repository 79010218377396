import styled from "styled-components";
import * as React from "react";
import {ActionBt, TagBt} from "../common-elements";
import gradientNft from './../assets/images/gradient_nft.png';
import {SOUND_MARKS} from "./SoundLoader";

export const NftCard = (props) => {
    const {imgSrc} = props;
    return (
        <Wrapper >
            <Image img={imgSrc}/>
            <div className={'info'}>
                <Name>
                    Имя персонажа
                </Name>
                <Type>
                    Подтип
                </Type>
             <TagContainer>
                 <div className={'item'}>
                     <TagBt>
                         Lola
                     </TagBt>
                 </div>
                 <div className={'item'}>
                     <TagBt>
                         Lola
                     </TagBt>
                 </div>
                 <div className={'item'}>
                     <TagBt>
                         Lola
                     </TagBt>
                 </div>
                 <div className={'item'}>
                     <TagBt>
                         Lola
                     </TagBt>
                 </div>
             </TagContainer>
                <ActionBt className={`item ${SOUND_MARKS.CLICK} ${SOUND_MARKS.HOVER} `}>
                    Купить
                </ActionBt>
            </div>
        </Wrapper>
    )
}

const TagContainer = styled.div`
    display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
  
  .item{
    margin-bottom: 10px;
    margin-right: 10px;
  }
`;

const Wrapper = styled.div`
  width: 330px;
  height: 590px;
  border: 2px solid #646879;
  border-radius:5px;
  background-color: #646879;
  overflow: hidden;
  position: relative;
  
  
  .info{
    position: absolute;
    z-index: 2;
    bottom: 0;
    padding: 20px;
    background-image: url(${gradientNft});
    background-size: contain;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
`;

const Name = styled.div`
  font-family: ${({theme}) => theme.fonts.seldom};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
`;

const Type = styled.div`
  margin-top: 5px;
  font-family: ${({theme}) => theme.fonts.ubuntuMono};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
  opacity: 0.9;
`;

const Image = styled.div`
  background-image: url(${(props) => props.img});
  width: 100%;
  height:100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
`;
