import React, {useEffect, useMemo, useState} from 'react';
import {ApplicationCoreContext} from "./ApplicationCoreContext";
import {isBrowser} from "../services/utils";
import Crypto from "../services/crypto";

export const UserProfileContext = React.createContext(null);

export const  UserProfileContextProvider = (
    {
        children
    }
) => {
    const applicationContext = React.useContext(ApplicationCoreContext);

    const [userTokenWallet, setUserTokenWallet] = useState(null);

    const initialContextState = useMemo(() => ({
        userTokenWallet,
        setUserTokenWallet
    }), [userTokenWallet])


    useEffect(() => {
        if(applicationContext?.appIsLoaded && isBrowser){
            Crypto.initiateCryptoToken().then((value) => {
                if(value != null){
                    setUserTokenWallet({
                        address: value,
                        type: 'null'
                    })
                }
            })
        }
    }, [applicationContext])

    return(
        <UserProfileContext.Provider value={initialContextState}>
            {children}
        </UserProfileContext.Provider>
    )
}
