import styled from "styled-components";
import * as React from "react";
import headerBg from './../../assets/images/bg_top-header.png'
import {TopMenuSection} from "../TopMenuSection";
import {Content} from "../../common-elements";
import logoIcon from './../../assets/images/orion_log.png';
import './../../spark.css';
import {SOUND_MARKS} from "../SoundLoader";
import {UserProfile} from "../UserProfile";
import {useContext} from "react";
import {UserProfileContext} from "../../context/UserProfileContext";
import Crypto from "../../services/crypto";

export const HeaderBlock = () => {
    const contextUserProfile = useContext(UserProfileContext);
    return (
        <Wrapper>
            <div className="ag-sparks">
                <div className="ag-spark"></div>
                <div className="ag-lightning"></div>
            </div>

            <div className={'inner-cont'}>
                <Content>
                    <div className={'menu'}>
                        <a href="/" ><Logo src={logoIcon}/></a>
                        <TopMenuSection/>
                        {contextUserProfile?.userTokenWallet != null
                            ? <UserProfile walletInfo={contextUserProfile?.userTokenWallet}/>
                            : null
                        }
                    </div>
                    <div className={'information-block'}>
                        <h1>
                            Take control of <br/>
                            a shattered empire 
                        </h1>

                        <div className={'description'}>
                        Embark on a journey through the galaxy and unleash your entrepreneurial spirit in this thrilling economic strategy game. 
                        Lead and manage star systems, extract valuable resources, 
                        and grow your NFT characters. With endless possibilities and non-stop action, this game is the ultimate test of your 
                        strategic skills. Experience the excitement of rebuilding and expanding your empire now!
                        </div>


                        <div className={`reg-bt ${SOUND_MARKS.HOVER} ${SOUND_MARKS.CLICK} cursor-link`}
                             onClick={async () => {
                                 try {
                                     const token = await Crypto.loginUserWithEth();
                                 } catch (e) {
                                     console.log('error Log:> [TopMenuSection.js] :=', e);
                                 }
                             }}>
                            {contextUserProfile?.userTokenWallet == null ? 'PLAY GAME' : 'PLAY GAME'}
                        </div>

                        <div className={'call-action'}></div>
                    </div>
                </Content>

            </div>
        </Wrapper>
    )
}


const Wrapper = styled.div`
  padding: 5px;
  position: relative;
  min-height: 1000px;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url(${headerBg});
  background-size: contain;
  
  .information-block{
    box-sizing: border-box;
    margin-top: 80px;
    
    h1{
      text-transform: uppercase;
      font-size: 44px;
      line-height: 62px;
      color: #267BFF;
    }
    
    .description{
      font-family: ${({theme}) => theme.fonts.ubuntuMono};
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #FFFFFF;
      margin-top:65px;
      opacity: 0.9;
      width: 70%;
    }
    
    .call-action{
      font-family: ${({theme}) => theme.fonts.seldom};
      font-style: normal;
      font-weight: 400;
      font-size: 13px;  
      line-height: 24px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #FFFFFF;
    }
    
    .reg-bt{
      background: rgba(38, 123, 255, 0.2);
      border: 1px solid #267BFF;
      border-radius: 4px;
      width: fit-content;
      padding: 15px 20px;
      box-sizing: border-box;
      margin-top: 53px;
      margin-bottom: 23px;
      
      :hover {
        box-shadow: 0 0 8px 1px #164086;
      }
    }
  }

  .inner-cont {
    position: relative;
    z-index: 10;
  }

  .menu {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;


const Logo = styled.img`

`;
