/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment"

function SEO({
                 description,
                 lang,
                 meta,
                 hasNavigation = false,
                 title,
                 keywords,
                 nextNav,
                 prevNav,
                 pageUrl = '',
                 publishedDate,
                 updateDate
             }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `
    )

    const metaDescription = description || site.siteMetadata.description
    const metaTitle = title || site.siteMetadata.title
    const locale = lang === 'ru' ? 'ru_RU' : 'en_GB';

    const publishDate = publishedDate || site.siteMetadata.releaseDate;
    const updateTime = updateDate == null ? moment().format() : moment(updateDate).format();
    const environmentPath = process.env.GATSBY_MODE_ENV === 'prod'
        ? process.env.GATSBY_PROD_URL
        : process.env.GATSBY_DEV_URL;
    // const baseUrl = process.env.GATSBY_LANG_ENV === 'ru' ? `${environmentPath}/ru/` : `${environmentPath}/`;
    //
    // const pUrl = `${baseUrl}${pageUrl}`

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={metaTitle}
            titleTemplate={`%s `}
            meta={[
                {
                    property: `og:title`,
                    content: metaTitle,
                },
                {
                    property: `og:site_name`,
                    content: 'iProxy.online',
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },

                //  BASIC PARAMS
                {
                    property: `og:type`,
                    content: `website`,
                },
                // {
                //     property: `og:url`,
                //     content: pUrl,
                // },
                {
                    property: `og:locale`,
                    content: locale,
                },
                {
                    property: `og:locale:alternate`,
                    content: [`en_US, en_CA, en_GB, en_EN, ru_RU`],
                },

                //  PUBLISH INFO
                {
                    property: `article:published_time`,
                    content: publishDate,
                },
                {
                    property: `article:modified_time`,
                    content: updateTime,
                },
                {
                    property: `article:author`,
                    content: 'iProxy.online',
                },
                {
                    property: `article:section`,
                    content: metaDescription,
                },
                // {
                //   property: `article:tag`,
                //   content: keywords,
                // },

                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ]
                //   .concat(
                //   keywords.length > 0
                //     ? {
                //       name: `keywords`,
                //       content: keywords.join(`, `),
                //     }
                //     : []
                // ).concat(meta)

            }
        >
            {/*<link rel="alternate" href="https://iproxy.online/ru" hrefLang='ru' key='ru'/>*/}
            {/*<link rel="alternate" href="https://iproxy.online/" hrefLang='en' key='en'/>*/}
            {/*<link rel="alternate" hrefLang="x-default" href="https://iproxy.online/"/>*/}
            {/*<link rel="sitemap" type="application/xml" href="https://iproxy.online/seo/sitemap.xml"/>*/}

            {/*{hasNavigation ? <link rel="next" href={nextNav} /> : null}*/}
            {/*{hasNavigation ? <link rel="prev" href={prevNav} /> : null}*/}
            <script
                src="https://cdnjs.cloudflare.com/ajax/libs/web3/1.6.1/web3.min.js"
                integrity="sha512-5erpERW8MxcHDF7Xea9eBQPiRtxbse70pFcaHJuOhdEBQeAxGQjUwgJbuBDWve+xP/u5IoJbKjyJk50qCnMD7A=="
                crossOrigin="anonymous"
                referrerpolicy="no-referrer"
            ></script>

           
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    keywords: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.string),
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
