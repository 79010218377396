import styled from 'styled-components';

export const Content = styled.div`
  max-width: 1100px;
  width: 100%;
  position: relative;
  margin: auto;
`;


export const TitleBlock = styled.div`
  font-family: ${({theme}) => theme.fonts.seldom };
  font-weight: 400;
  font-size: 32px;
  line-height: 37px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.white };
`;

export const ActionBt = styled.div`
  font-family: ${({theme}) => theme.fonts.ubuntuMono};
  width: fit-content;
  padding: 18px 15px;
  background: rgba(38, 123, 255, 0.2);
  border: 1px solid #267BFF;
  box-sizing: border-box;
  border-radius: 4px;
  transition: .2s;
  
  :hover{
    box-shadow: 0 0 8px 1px #277bff;

  }
  
`;

export const TagBt = styled.div`
  width: fit-content;
  border: 1px solid #a9aab7;
  box-sizing: border-box;
  color: #FFFFFF;
  padding: 10px;
  font-weight: 700;
  font-size: 12px;
  border-radius: 4px;
`
