import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled, {css} from 'styled-components';
import roadMapCircle from './../../assets/images/roadmap_circle_map.png';
import roadLine from './../../assets/images/road_line.png'
import {Content, TitleBlock} from "../../common-elements";
import useWindowSize from "@rooks/use-window-size"

let timerClearLink;

export const RoadmapItem = ({config}) => {
    const rref = useRef(null);
    const [activeWidth, setActiveWidth] = useState(0);

    const { innerWidth } = useWindowSize();

    useEffect(() => {
        clearTimeout(timerClearLink);
        timerClearLink = setTimeout(() => {
            const lastActiveKey =  config?.reduce((accum, value, index) => {
                if(value?.active){
                    return value.key;
                }
                return accum;
            }, null)

            const itemIndex = config.findIndex((c) => c.key === lastActiveKey);
            if(itemIndex == config.length - 1){
                setActiveWidth(innerWidth);
            }else {
                const getPoint = document.getElementById(`road-point-${itemIndex}`);
                const {left, right} = getPoint.getBoundingClientRect();
                setActiveWidth(right);
            }

        },10)
    }, [innerWidth])


    return (
        <Wrapper ref={rref}>
            <RoadLine activeWidth={activeWidth}/>
           <Content>


               <RoadContainer>
               {
                   config?.map((segment, skey) => {
                       return (
                           <ItemContainer key={skey} >
                               <Circle active={segment.active} id={`road-point-${skey}`}>
                                   {segment?.key}
                               </Circle>
                               {
                                   segment?.steps?.map((step, skey) => (
                                       <BlockContainer key={skey} active={step.status}>
                                           {step.value}
                                       </BlockContainer>
                                   ))
                               }
                           </ItemContainer>
                       )
                   })
               }
               </RoadContainer>
           </Content>

        </Wrapper>
    );
}

const Wrapper = styled.div`
  position: relative;
  .title-bl{
    text-align: center;
  }
`;

const RoadLine = styled.div`
  position: absolute;
  z-index: 1;
  margin-top: 45px;
  width: 100%;
  height: 2px;
  background-image: url(${roadLine});
  background-position-y: 3px;


  :before {
    content:'';
    display: block;
    background-image: url(${roadLine});
    background-position-y: 0px;
    height: 3px;
    width: ${(props) => props.activeWidth}px;

  }
`;

const RoadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;


const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;
`;


const Circle = styled.div`
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 100px;
  background-image: url(${roadMapCircle});
  background-position-y: ${(props) => props?.active ? '0px' : '96px'};
`;

const BlockContainer = styled.div`

  border-radius: 8px;
  max-width: 250px;
  width: fit-content;
  box-sizing: border-box;
  padding: 30px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  position: relative;
  background: ${(props) => props.active ? 'linear-gradient(0deg, rgba(38, 123, 255, 0.05), rgba(38, 123, 255, 0.05)), #020D18' : 'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(255, 255, 255, 0.1)'};
  border: ${(props) => props.active ? '0.810823px solid rgba(38, 123, 255, 0.5)' : '0.810823px solid rgba(255, 255, 255, 0.5)'};
  margin-top: 40px;
  margin-bottom: 22px;
  transition: 0.2s;

  :before {
    display: block;
    content: '';
    width: 1px;
    height: 60px;
    background-color: ${(props) => props.active ? 'rgba(38, 123, 255, 0.5)' : 'rgba(255, 255, 255, 0.5)'};
    position: absolute;
    top: -62px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  
  :hover {
    box-shadow: ${(props) => props.active ? '0 0 8px 1px #164086' : '0 0 8px 1px #5c5c5c'}
  }
`;

